/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// import MDBadge from "components/MDBadge";
import { Button } from "@mui/material";
import {  useNavigate } from "react-router-dom";
// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

export default function Data() {
  const navigate = useNavigate();
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  return {
    columns: [
      { Header: "Name", accessor: "author", width: "45%", align: "left" },
      { Header: "Address", accessor: "address", align: "left" },
      { Header: "Phone", accessor: "phone", align: "center" },
      // { Header: "Amount", accessor: "loan", align: "center" },
      { Header: "Pan", accessor: "pan", align: "center" },
      { Header: "Aadhaar", accessor: "aadhaar", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: [
      {
        author: <Author image={team2} name="Siddharth Koshy" email="sid@gmail.com" />,
        pan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            BBDEW8735N
          </MDTypography>
        ),
        phone: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            9876543216
          </MDTypography>
        ),
        aadhaar: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            1234268863683
          </MDTypography>
        ),
        address: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Karol Bagh
          </MDTypography>
        ),
        loan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            ₹20,000
          </MDTypography>
        ),
        action: (
          <>
            <Button
              variant="contained"
              color="text"
              fontWeight="medium"
              onClick={()=>navigate("/agent-form")}
            >
              View
            </Button>
          </>
        ),
      },
      {
        author: <Author image={team3} name="Ayaan Srivastava" email="ayaan@gmail.com" />,
        pan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            BHUBC8726Z
          </MDTypography>
        ),
        phone: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            9876543343
          </MDTypography>
        ),
        aadhaar: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            1742167862693
          </MDTypography>
        ),
        address: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Tilak Nagar
          </MDTypography>
        ),
        loan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            ₹90,000
          </MDTypography>
        ),
        action: (
          <Button
          variant="contained"
          color="text"
          fontWeight="medium"
          onClick={()=>navigate("/agent-form")}
        >
          View
        </Button>
        ),
      },
      {
        author: <Author image={team4} name="Akshi Dora" email="akshi@gmail.com" />,
        pan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            BWSBU8735J
          </MDTypography>
        ),
        phone: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            9876545417
          </MDTypography>
        ),
        aadhaar: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            125428862694
          </MDTypography>
        ),
        address: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Janak Puri
          </MDTypography>
        ),
        loan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            ₹1,00,000
          </MDTypography>
        ),
        action: (
          <Button
          variant="contained"
          color="text"
          fontWeight="medium"
          onClick={()=>navigate("/agent-form")}
        >
          View
        </Button>
        ),
      },
      {
        author: <Author image={team3} name="Arjun Sama" email="arjun@gmail.com" />,
        pan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            IEBAM8729X
          </MDTypography>
        ),
        phone: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            9876541579
          </MDTypography>
        ),
        aadhaar: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            735267862915
          </MDTypography>
        ),
        address: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Lodhi Colony
          </MDTypography>
        ),
        loan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            ₹80,000
          </MDTypography>
        ),
        action: (
          <Button
          variant="contained"
          color="text"
          fontWeight="medium"
          onClick={()=>navigate("/agent-form")}
        >
          View
        </Button>
        ),
      },
      {
        author: <Author image={team3} name="Kavita Mann" email="kavita@gmail.com" />,
        pan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            HDBEJ8735S
          </MDTypography>
        ),
        phone: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            9876547532
          </MDTypography>
        ),
        aadhaar: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            123534544351
          </MDTypography>
        ),
        address: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Palam
          </MDTypography>
        ),
        loan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            ₹20,000
          </MDTypography>
        ),
        action: (
          <Button
          variant="contained"
          color="text"
          fontWeight="medium"
          onClick={()=>navigate("/agent-form")}
        >
          View
        </Button>
        ),
      },
      {
        author: <Author image={team4} name="Minali Behl" email="minali@gmail.com" />,
        pan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            BWEPG8735N
          </MDTypography>
        ),
        phone: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            9876548532
          </MDTypography>
        ),
        aadhaar: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            123567864353
          </MDTypography>
        ),
        address: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Ashok Vihar
          </MDTypography>
        ),
        loan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            ₹50,000
          </MDTypography>
        ),
        action: (
          <Button
          variant="contained"
          color="text"
          fontWeight="medium"
          onClick={()=>navigate("/agent-form")}
        >
          View
        </Button>
        ),
      },
    ],
  };
}
