

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Billing page components
import Bill from "layouts/billing/components/Bill";

function BillingInformation() {
  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Recent Transaction
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <Bill
            name="oliver liam"
            address="Vasant Vihar"
            email="oliver@gmail.com"
            tNumber="FRB1237631"
            phone="9259542691"
            amount="₹80,000"
            date="02/01/2023"
            time="03:23pm"
          />
          <Bill
            name="jaspreet singh"
            address="Tilak Nagar"
            email="jassi@gmail.com"
            tNumber="GEM1239319"
            phone="9926542607"
            amount="₹1,00,000"
            date="17/03/2023"
            time="02:02pm"
          />
          <Bill
            name="Samart Tyagi"
            address="Najafgarh"
            email="samart@gmail.com"
            tNumber="HRC123566"
            phone="9876231910"
            amount="₹50,000"
            date="02/02/2023"
            time="01:00pm"
          />
          <Bill
            name="Ajay Nagar"
            address="Janak Puri"
            email="ajay@gmail.com"
            tNumber="UVE1235369"
            phone="9876545418"
            amount="₹99,000"
            date="02/12/2022"
            time="11:23am"
          />
          <Bill
            name="steve mark"
            address="Dwarka"
            email="steve@gmail.com"
            tNumber="NEX1235476"
            phone="9877280210"
            amount="₹20,000"
            date="28/12/2022"
            time="09:18pm"
          />
          <Bill
            name="lucas harper"
            address="Laxmi Nagar"
            email="lucas@gmail.com"
            tNumber="FRB1235476"
            phone="9876543210"
            amount="₹80,000"
            date="21/08/2022"
            time="07:00pm"
          />
          <Bill
            name="ethan james"
            address="Karol Bagh"
            email="ethan@gmail.com"
            tNumber="BWC1936476"
            phone="9826943210"
            amount="₹60,000"
            date="18/03/2022"
            time="09:48am"
            noGutter
          />
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BillingInformation;
