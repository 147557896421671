import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ErrorMessage, Formik } from "formik";
import { mixed, object, string } from "yup";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { makeStyles, Grid } from "@material-ui/core";
import Footer from "examples/Footer";
import { Button } from "@mui/material";

const useStyles = makeStyles({
  formSection: {
    padding: "2rem",
  },
});

const CustomErrorMessage = ({ children }) => (
  <span style={{ color: "red", fontSize: "14px" }}>{children}</span>
);

const validationSchema = object().shape({
  file1: mixed()
    .required("File is required")
    .test(
      "fileFormat",
      "Unsupported file format",
      (value) => value && ["image/jpeg", "image/png"].includes(value.type)
    )
    .test("fileSize", "File 1 size is too large", (value) => value && value.size <= 5000000),
  file2: mixed()
    .required("File is required")
    .test(
      "fileFormat",
      "Unsupported file format",
      (value) => value && ["image/jpeg", "image/png"].includes(value.type)
    )
    .test("fileSize", "File 2 size is too large", (value) => value && value.size <= 5000000),
  file3: mixed()
    .required("File is required")
    .test(
      "fileFormat",
      "Unsupported file format",
      (value) => value && ["image/jpeg", "image/png"].includes(value.type)
    )
    .test("fileSize", "File 2 size is too large", (value) => value && value.size <= 5000000),
  remark: string().required("Remark is required"),
  radio1: string().required("A radio option is required"),
  radio2: string().required("A radio option is required"),
  radio3: string().required("A radio option is required"),
});

const AgentForm = () => {
  const classes = useStyles();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          file1: null,
          file2: null,
          file3: null,
          remark: "",
          radio1: "",
          radio2: "",
          radio3: "",
        }}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          validationSchema
            .validate(values)
            .then(() => {
              console.log(values);
            })
            .catch((err) => {
              setFieldError("file", err.errors[0]);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ handleChange, handleSubmit, values, touched, setFieldValue, errors, isValid }) => (
          <form className={classes.formSection}>
            <h1>Upload the Documents Here</h1>
            <div className={classes.formSection}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <label>Address Proof:</label>
                </Grid>
                <Grid item xs={6}>
                  <input
                    name="file1"
                    type="file"
                    onChange={(event) => {
                      setFieldValue("file1", event.currentTarget.files[0]);
                    }}
                  />
                  <ErrorMessage component={CustomErrorMessage} name="file1" />
                </Grid>
                <Grid item xs={6}>
                  <label>Aadhaar Card:</label>
                </Grid>
                <Grid item xs={6}>
                  <input
                    name="file2"
                    type="file"
                    onChange={(event) => {
                      setFieldValue("file2", event.currentTarget.files[0]);
                    }}
                  />
                  <ErrorMessage component={CustomErrorMessage} name="file2" />
                </Grid>
                <Grid item xs={6}>
                  <label>Pan Card:</label>
                </Grid>
                <Grid item xs={6}>
                  <input
                    name="file3"
                    type="file"
                    onChange={(event) => {
                      setFieldValue("file3", event.currentTarget.files[0]);
                    }}
                  />
                  <ErrorMessage component={CustomErrorMessage} name="file3" />
                </Grid>
                <Grid item xs={6}>
                  <label>Is Address Proof valid?</label>
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <RadioGroup
                      row
                      name="radio1"
                      onChange={(event) => {
                        setFieldValue("radio1", event.currentTarget.value);
                      }}
                    >
                      <FormControlLabel value="yes" name="radio1" control={<Radio />} label="Yes" />
                      <FormControlLabel value="no" name="radio1" control={<Radio />} label="No" />
                    </RadioGroup>
                    <ErrorMessage component={CustomErrorMessage} name="radio1" />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <label>Is Pan valid?</label>
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <RadioGroup
                      row
                      name="radio2"
                      onChange={(event) => {
                        setFieldValue("radio2", event.currentTarget.value);
                      }}
                    >
                      <FormControlLabel value="yes" name="radio2" control={<Radio />} label="Yes" />
                      <FormControlLabel value="no" name="radio2" control={<Radio />} label="No" />
                    </RadioGroup>
                    <ErrorMessage component={CustomErrorMessage} name="radio2" />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <label>Is Aadhaar valid?</label>
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <RadioGroup
                      row
                      name="radio3"
                      onChange={(event) => {
                        setFieldValue("radio3", event.currentTarget.value);
                      }}
                    >
                      <FormControlLabel value="yes" name="radio3" control={<Radio />} label="Yes" />
                      <FormControlLabel value="no" name="radio3" control={<Radio />} label="No" />
                    </RadioGroup>
                    <ErrorMessage component={CustomErrorMessage} name="radio3" />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <label>Remark:</label>
                </Grid>
                <Grid item xs={6}>
                  <input
                    type="text"
                    name="remark"
                    value={values.remark}
                    onChange={handleChange("remark")}
                    style={{ padding: ".5rem", marginRight: "4rem" }}
                  />
                  <ErrorMessage component={CustomErrorMessage} name="remark" />
                </Grid>
              </Grid>
            </div>
            <Button
              variant="contained"
              color="text"
              fontWeight="medium"
              onClick={() => {
                handleSubmit();
              }}
              size="large"
              component="label"
              disabled={!isValid}
            >
              Upload
            </Button>
          </form>
        )}
      </Formik>
      <Footer />
    </DashboardLayout>
  );
};

export default AgentForm;
