/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

export default function Data() {
  const Author = ({ image, name, email}) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "Clients", accessor: "author", width: "45%", align: "left" },
    //   { Header: "Agent", accessor: "videoKYC", align: "center" },
      { Header: "Occupation", accessor: "function", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "Loan", accessor: "loan", align: "center" },
      { Header: "start date", accessor: "employed", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: [
      {
        author: (
          <Author image={team2} name="Bhima Sarkar" email="bhima@gmail.com"  />
        ),
        function: <Job title="Engineer" description="XYZ" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Active" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            30/05/17
          </MDTypography>
        ),
        pan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            BYGVW8935H
          </MDTypography>
        ),
        phone:(
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          9876547429
        </MDTypography>
        ),
        aadhaar: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            679248628693560
          </MDTypography>
        ),
       
        loan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            ₹7,000
          </MDTypography>
        ),
        action: (
          <>
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              Edit
            </MDTypography>
          </>
        ),
      },
      {
        author: (
          <Author image={team3} name="Sahadeva Viswan" email="sahadeva@gmail.com" />
        ),
        function: <Job title="Manager" description="Bsev" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="pending" color="dark" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            19/08/12
          </MDTypography>
        ),
        pan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            BFUGQ8478G
          </MDTypography>
        ),
        phone:(
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          9879512255
        </MDTypography>
        ),
        aadhaar: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            1745927864683
          </MDTypography>
        ),
        loan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            ₹8,000
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
      {
        author: (
          <Author image={team4} name="Hira Bhattacharya" email="hira@gmail.com" />
        ),
        function: <Job title="Executive" description="Projects" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="active" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            02/10/22
          </MDTypography>
        ),
        pan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            BGECU8457C
          </MDTypography>
        ),
        phone:(
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          9823845693
        </MDTypography>
        ),
        aadhaar: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            12246885614
          </MDTypography>
        ),
        loan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            ₹5,000
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
      {
        author: (
          <Author image={team3} name="Ranjit Dayal" email="ranjit@gmail.com"  />
        ),
        function: <Job title="service" description="GHP" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="active" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            13/11/19
          </MDTypography>
        ),
        pan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            IEBUX8753G
          </MDTypography>
        ),
        phone:(
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          94597544582
        </MDTypography>
        ),
        aadhaar: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            733482861584
          </MDTypography>
        ),
        loan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            ₹14,000
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
      {
        author: (
          <Author image={team3} name="Rahul Pande" email="rahul@gmail.com" />
        ),
        function: <Job title="Manager" description="Executive" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="pending" color="dark" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            04/01/23
          </MDTypography>
        ),
        pan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            HRVUJ5735S
          </MDTypography>
        ),
        phone:(
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          9876127432
        </MDTypography>
        ),
        aadhaar: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            123575543728
          </MDTypography>
        ),
        loan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            ₹10,000
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
      {
        author: (
          <Author image={team4} name="Dyal Sanyal" email="dyal@gmail.com" />
        ),
        function: <Job title="Programator" description="Developer" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="pending" color="dark" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            14/09/20
          </MDTypography>
        ),
        pan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            BWEPG8735N
          </MDTypography>
        ),
        phone:(
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          9876548532
        </MDTypography>
        ),
        aadhaar: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            123567864353
          </MDTypography>
        ),
        loan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            ₹20,000
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
    ],
  };
}
